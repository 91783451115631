import React, { useEffect } from 'react';
import { useState } from 'react';
import { Divider, useTheme } from '@mui/material';
import Row from './Row';

// mui
import {
    Box,
    MenuItem,
    Modal,
    IconButton,
    Typography,
    Button,
} from '@mui/material';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { isoToReadableDate } from 'src/utils/isoToReadableDate';
import { changeWithdrawStatus } from 'src/api/withdrawals/changeWithdrawStatus';

// Modal Style
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        width: '90%',
        paddingX: 3,
    },
};

function WithdrawalModal({ notify, withdrawal }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const theme = useTheme();

    const sendWithdrawStatus = async (status) => {
        if (status && withdrawal && withdrawal._id) {
            try {
                const response = await changeWithdrawStatus(
                    status,
                    withdrawal._id
                );
                if (response) {
                    if (notify) {
                        notify('edit');
                    }
                    handleClose();
                }
            } catch (error) {
                console.log(error);
                if (notify) {
                    notify('error');
                }
                handleClose();
            }
        }
    };

    const handleApprove = () => {
        sendWithdrawStatus('approved');
    };

    const handleDecline = () => {
        sendWithdrawStatus('declined');
    };

    console.log('WITHDRAWAL: ' + JSON.stringify(withdrawal));

    return (
        <Box>
            <MenuItem onClick={handleOpen}>
                <EditRoundedIcon sx={{ marginRight: '1rem' }} />
                Редактировать
            </MenuItem>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-withdrawal-edit'
                aria-describedby='modal-withdrawal-edit'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {withdrawal ? (
                        <Box
                            sx={{
                                margin: '0',
                                padding: '0',
                                width: '100%',
                                marginBottom: '0.5rem',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '1.8rem',
                                }}
                            >
                                Детали вывода средств
                            </Typography>
                            <Box
                                sx={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    padding: '1rem 2rem',
                                }}
                            >
                                <Row
                                    label='ID'
                                    value={
                                        withdrawal._id ? withdrawal._id : null
                                    }
                                />

                                <Row
                                    label='ID пользователя'
                                    value={
                                        withdrawal.user ? withdrawal.user : null
                                    }
                                />

                                <Row
                                    label='Дата'
                                    value={
                                        withdrawal.createdAt
                                            ? isoToReadableDate(
                                                  withdrawal.createdAt
                                              )
                                            : null
                                    }
                                />

                                <Row
                                    label='Сумма'
                                    value={
                                        withdrawal.amount
                                            ? withdrawal.amount
                                            : null
                                    }
                                />

                                <Row
                                    label='Валюта'
                                    value={
                                        withdrawal.currency
                                            ? withdrawal.currency
                                            : null
                                    }
                                />

                                <Row
                                    label='Метод вывода средств'
                                    value={
                                        withdrawal.method
                                            ? withdrawal.method
                                            : null
                                    }
                                />

                                <Row
                                    label='Адрес'
                                    value={
                                        withdrawal.methodAddress
                                            ? withdrawal.methodAddress
                                            : null
                                    }
                                />

                                <Row
                                    label='Заметка'
                                    value={
                                        withdrawal.notes
                                            ? withdrawal.notes
                                            : null
                                    }
                                />
                            </Box>
                            <Box
                                sx={{
                                    boxSizing: 'border-box',
                                    marginTop: '1rem',
                                    padding: '1rem 2rem',
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    '@media (max-width: 650px)': {
                                        paddingX: '0',
                                    },
                                    '@media (max-width: 400px)': {
                                        rowGap: '2rem',
                                        flexDirection: 'column',
                                    },
                                }}
                            >
                                <Button
                                    variant='contained'
                                    size='large'
                                    sx={{
                                        backgroundColor: `${theme.palette.error.main}`,
                                    }}
                                    onClick={() => {
                                        handleDecline();
                                    }}
                                >
                                    ОТКЛОНИТЬ
                                </Button>
                                <Button
                                    variant='contained'
                                    size='large'
                                    sx={{
                                        backgroundColor: `${theme.palette.success.main}`,
                                    }}
                                    onClick={() => {
                                        handleApprove();
                                    }}
                                >
                                    УТВЕРДИТЬ
                                </Button>
                            </Box>
                        </Box>
                    ) : null}
                </Box>
            </Modal>
        </Box>
    );
}

export default WithdrawalModal;
